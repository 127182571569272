import React from 'react';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Resume from './components/Resume';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import ProjectsView from './components/ProjectsView';
import ContactForm from './components/ContactForm';
import Signup from './components/Signup';
import Login from './components/Login';
import Footer from './components/Footer';
import EmailVerificationPage from './components/EmailVerification';
import ThankYouPage from './components/ThankYouPage'; // Import your login page component
import Summary from './components/Summary'; // Import the Summary component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import VerifyEmail from './components/VerifyEmail';
import ObjectDetection from './components/ObjectDetection';
import EmptyPage from './components/EmptyPage';
import Transcription from './components/Transcription';
import TechGallery from './components/TechGallery';
import Admin from './components/Admin';
import './App.css';

function App() {
  return (
    <CookiesProvider>
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<ProjectsPage />} /> {/* Route for Projects page */}
          <Route path="/thank-you" element={<ThankYou />} /> {/* Route for login page */}
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/verify-email/:token/:email" element={<VerifyEmail />} />
          <Route path="/emailverification" element={<EmailVerificationPageFunction />} />
          <Route path="/summary" element={<SummaryProject />} /> {/* Route for the Summary component */}
          <Route path="/computer-vision" element={<ObjectDetectionProject />} /> {/* Route for the ObjectDetection   component */}
          <Route path="/empty" element={<EmptyPage />} /> {/* Route for the ObjectDetection   component */}
          <Route path="/transcription" element={<TranscriptionProject />} /> {/* Route for the ObjectDetection   component */}
          <Route path="/tech-gallery" element={<TechGalleryPage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </div>
    </Router>
    </CookiesProvider>

  );
}

// Home component for the root path
function Home() {
  return (
    <div className="home-background">
      <Navbar />
      <Header />
      <Resume />
      <Projects />
      <AboutMe />
      <ContactForm />
      <Footer />
    </div>
  );
}

// Define the ProjectsPage function to render the ProjectsView component
function ProjectsPage() {
  return (
    <div className="home-background">
      <Navbar />
      <ProjectsView />
      <Footer />
    </div>
  );
}

// Define the SummaryProject function to render the ProjectsView component
function SummaryProject() {
  return (
    <div className="home-background">
      <Navbar />
      <Summary />
      <Footer />
    </div>
  );
}

// Define the TranscriptionProject function to render the ProjectsView component
function TranscriptionProject() {
  return (
    <div className="home-background">
      <Navbar />
      <Transcription />
      <Footer />
    </div>
  );
}

// Define the TranscriptionProject function to render the ProjectsView component
function ObjectDetectionProject() {
  return (
    <div className="home-background">
      <Navbar />
      <ObjectDetection />
      <Footer />
    </div>
  );
}

// Define the SignUp function to render the ProjectsView component
function SignUpPage() {
  return (
    <div className="home-background">
      <Navbar />
      <Signup />
      <Footer />
    </div>
  );
}

// Define the Login function to render the ProjectsView component
function LoginPage() {
  return (
    <div className="home-background">
      <Navbar />
      <Login />
      <Footer />
    </div>
  );
}

// Define the ThankYou function to render the ProjectsView component
function ThankYou() {
  return (
    <div className="home-background">
      <Navbar />
      <ThankYouPage />
      <Footer />
    </div>
  );
}

// Define the EmailVerification function to render the ProjectsView component
function EmailVerificationPageFunction() {
  return (
    <div className="home-background">
      <Navbar />
      <EmailVerificationPage />
      <Footer />
    </div>
  );
}

// Define the TechGallery function to render the ProjectsView component
function TechGalleryPage() {
  return (
    <div className="home-background">
      <Navbar />
      <TechGallery />
      <Footer />
    </div>
  );
}

function AdminPage() {
  return (
    <div className="home-background">
      <Navbar />
      <Admin />
      <Footer />
    </div>
  );
}

// // Define the DataBricks function to render the ProjectsView component
// function DataBricksPage() {
//   return (
//     <div className="home-background">
//       <Navbar />
//       <DataBricks />
//       <Footer />
//     </div>
//   );
// }

export default App;
