import React, { useEffect, useState } from 'react';
import '../styles/admin.css';
import { BASE_URL } from '../config/config.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminPage = () => {
  const [adminData, setAdminData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const accessResponse = await axios.get(`${BASE_URL}/api/admin-access`);
        console.log(accessResponse.data)
        setAdminData(accessResponse.data);

        const dataResponse = await axios.get(`${BASE_URL}/api/admin-data`);
        setAdminData(dataResponse.data);

        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        if (err.response && err.response.status === 403) {
          setError('Access Denied: Unauthorized IP');
          navigate('/projects');
        } else {
          setError('An error occurred while fetching admin data.');
        }
        setLoading(false); // Set loading to false if there is an error
      }
    };

    fetchAdminData();
  }, [navigate]);

  // Function to handle row deletion
  const handleDelete = async (email) => {
    try {
      await axios.delete(`${BASE_URL}/api/admin-delete-user`, { data: { email } });
      setAdminData(adminData.filter((row) => row.email !== email));
    } catch (err) {
      console.error('Failed to delete user:', err);
      setError('Failed to delete user.');
    }
  };

  if (loading) {
    return <div>Loading admin data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (adminData.length === 0) {
    return <div>No records found.</div>; // Display this message if no data is found
  }

  return (
    <div>
      <h1>Admin Page</h1>
      <table className="admin-table">
        <thead>
          <tr>
            {Object.keys(adminData[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {adminData.map((row, index) => (
            <tr key={index}>
              {Object.values(row).map((value, i) => (
                <td key={i}>
                  {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
                </td>
              ))}
              <td>
                <button onClick={() => handleDelete(row.email)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;
