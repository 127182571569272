import React, { useState } from 'react';
import '../styles/Resume.css'; // Import the styles for the Resume component

const Resume = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const toggleModal = (imageSrc) => {
        setModalImageSrc(imageSrc);
        setIsModalOpen(!isModalOpen);
    };

    const closeModal = (e) => {
        if (e.target.className === 'modal') {
            setIsModalOpen(false);
        }
    };

    return (
        <div className="delimiter">
            <div className="section" id="resume">
                <h2 className="main-title">Resume</h2> {/* Apply main-title class */}
            <div className="resume" id="pdf-images" style={{ textAlign: 'center' }}>
<div className="resume-images-container">
                        <img
                            src="../../images/Michael-DeMaria-Resume-Page1.png"
                            alt="Resume Page 1"
                            className="resume-image"
                            onClick={() => toggleModal('../../images/Michael-DeMaria-Resume-Page1.png')}
                        />
                        <img
                            src="../../images/Michael-DeMaria-Resume-Page2.png"
                            alt="Resume Page 2"
                            className="resume-image"
                            onClick={() => toggleModal('../../images/Michael-DeMaria-Resume-Page2.png')}
                        />
                    </div>
                    <div className="btn-container">
                        <a href="../../images/Michael-DeMaria-Resume.pdf" download="Michael-DeMaria-Resume.pdf" className="btn">Download Resume</a>
                    </div>
                </div>
                {isModalOpen && (
                    <div className="modal" onClick={closeModal}>
                        <div className="modal-content">
                            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                            <img
                                src={modalImageSrc}
                                alt="Resume Image"
                                className="resume-image-full"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Resume;
